<template>
  <div>
    <button @click="add">add</button>
    <el-table :data="tableData" border max-height="800" :span-method="objectSpanMethod">
      <el-table-column prop="cloudTypeName" label="资质证书编号" width="200"></el-table-column>
      <el-table-column prop="accName" label="资质名称" width="250"></el-table-column>
      <el-table-column prop="crashAmount" label="发证日期" width="130"></el-table-column>
      <el-table-column prop="creditAmount" label="	发证有效期" width="130"></el-table-column>
      <el-table-column prop="availaleAmount" label="	发证机关" width="178"></el-table-column>
      <el-table-column prop="beian" label="	发证机关" width="178"></el-table-column>
      <el-table-column prop="price" label="	发证机关" width="178"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'demo',
  data() {
    return {
      // tableData: [
      //     {
      //         id: 1,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm1",
      //         crashAmount: 0.1,
      //         creditAmount: 240000.0,
      //         availaleAmount: 83548.66,
      //     },
      //     {
      //         id: 2,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm1",
      //         crashAmount: 0.0,
      //         creditAmount: 150000.0,
      //         availaleAmount: 83548.66,
      //     },
      //     {
      //         id: 3,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm1",
      //         crashAmount: 0.0,
      //         creditAmount: 150000.0,
      //         availaleAmount: 53045.89,
      //     },
      //     {
      //         id: 4,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm4",
      //         crashAmount: 0.0,
      //         creditAmount: 1351000.0,
      //         availaleAmount: 1244354.9,
      //     },
      //     {
      //         id: 5,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm4",
      //         crashAmount: 0.0,
      //         creditAmount: 0.0,
      //         availaleAmount: -5033739.0,
      //     },
      //     {
      //         id: 5,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm5",
      //         crashAmount: 0.0,
      //         creditAmount: 0.0,
      //         availaleAmount: -5033739.0,
      //     },
      //     {
      //         id: 5,
      //         cloudTypeName: "阿里云",
      //         accName: "glodon_gm5",
      //         crashAmount: 0.0,
      //         creditAmount: 0.0,
      //         availaleAmount: -5033739.0,
      //     },
      // ],
      // 那个字段写在前面就以哪个为开始，
      needMergeArr: ["cloudTypeName", "accName", "crashAmount", "creditAmount"],
      tableData: [
        {
          cloudTypeName: "国网华原",
          accName: "安徽省",
          crashAmount: "合肥市",
          creditAmount: "620",
          availaleAmount: "安装模式",
          beian: "备案模式",
          price: 100
        },

        {
          cloudTypeName: "国网华原",
          accName: "安徽省",
          crashAmount: "合肥市",
          creditAmount: "720",
          availaleAmount: "安装模式",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原",
          accName: "河南省",
          crashAmount: "郑州市",
          creditAmount: "620",
          availaleAmount: "安装模式",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原",
          accName: "河南省",
          crashAmount: "郑州市",
          creditAmount: "720",
          availaleAmount: "安装模式",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原",
          accName: "河南省",
          crashAmount: "郑州市",
          creditAmount: "720",
          availaleAmount: "安装模式1",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原",
          accName: "河南省",
          crashAmount: "郑州市",
          creditAmount: "720",
          availaleAmount: "安装模式2",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原",
          accName: "河南省",
          crashAmount: "郑州市",
          creditAmount: "720",
          availaleAmount: "安装模式3",
          beian: "备案模式",
          price: 100
        },
        {
          cloudTypeName: "国网华原1",
          accName: "安徽省",
          crashAmount: "合肥市",
          creditAmount: "720",
          availaleAmount: "安装模式",
          beian: "备案模式",
          price: 100
        },


      ],
      rowMergeArrs: [],
    }
  },
  mounted() {
    this.rowMergeArrs = this.rowMergeHandle(this.needMergeArr, this.tableData);
    console.log('数据', this.rowMergeArrs)
  },
  methods: {
    add() {
      const obj = [{
        cloudTypeName: "国网华原",
        accName: "安徽省",
        crashAmount: "芜湖市",
        creditAmount: "620",
        availaleAmount: "安装模式",
        beian: "备案模式",
        price: 100
      }]
      obj.forEach((item, index) => {
        this.tableData.forEach(item2, index2 => {
          if (item.cloudTypeName == item2.cloudTypeName) {
            this.tableData.splice(index, 0, obj)
          }
        })
        if (item.cloudTypeName == obj.cloudTypeName) {
          this.tableData.splice(index, 0, obj)
        }
      })
      // this.tableData.forEach((item,index)=>{
      //   if(item.cloudTypeName== obj.cloudTypeName){
      //     this.tableData.splice(index,0,obj)
      //   }
      // })
      // 刷新
      this.rowMergeArrs = this.rowMergeHandle(this.needMergeArr, this.tableData);
      localStorage.setItem("test", JSON.stringify(this.tableData));
    },
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {}; // 需要合并的对象
      arr.forEach((i, idx) => {
        console.log(idx, 171);
        console.log(arr, 94);
        needMerge[i] = { // 初始化需要合并的对象
          rowArr: [], // 合并行数
          rowMergeNum: 0, // 合并行数
        };
        if (idx == 0) {
          data.forEach((item, index) => {
            // 表格首个数据单独处理
            if (index === 0) { //
              needMerge[i].rowArr.push(1); // 非合并行数
              needMerge[i].rowMergeNum = 0; // 合并行数
            } else {
              if (item[i] === data[index - 1][i]) {
                needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1; // 合并行数
                needMerge[i].rowArr.push(0);  // 非合并行数
              } else {
                needMerge[i].rowArr.push(1);
                needMerge[i].rowMergeNum = index;
              }
            }
          });
        }

        else {
          let firstRowArr = needMerge[arr[0]].rowArr;
          let firstRowArrDeal = [];
          firstRowArr.forEach((item, index) => {
            if (item > 0) {
              firstRowArrDeal.push(index);
            }
          });
          data.forEach((item, index) => {
            let sign = false;
            if (firstRowArrDeal.indexOf(index) > 0) {
              needMerge[i].rowMergeNum = index;
              sign = true;
            }
            // 表格首个数据单独处理
            if (index === 0) {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = 0;
            } else {
              if (item[i] === data[index - 1][i]) {
                if (sign) {
                  needMerge[i].rowArr.push(1);
                } else {
                  needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
                  needMerge[i].rowArr.push(0);
                }
              } else {
                needMerge[i].rowArr.push(1);
                needMerge[i].rowMergeNum = index;
              }
            }
          });
        }
      });
      return needMerge;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(column.property, 152);
      for (let res in this.needMergeArr) {
        console.log(this.needMergeArr[res], 154);
        if (this.needMergeArr[res] == column.property) {
          return this.mergeAction(column.property, rowIndex, columnIndex);
        }
      }
    },
    mergeAction(val, rowIndex) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
  }
}
</script>
<style scoped></style>
