import { render, staticRenderFns } from "./text.vue?vue&type=template&id=46eec492&scoped=true"
import script from "./text.vue?vue&type=script&lang=js"
export * from "./text.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46eec492",
  null
  
)

component.options.__file = "text.vue"
export default component.exports